import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutenticacionService } from '../autenticacion.service';
import { PaqueteItemComponent } from '../paquete-item/paquete-item.component';
import { SharedService } from '../shared.service';
import { register, SwiperContainer } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-combos',
  templateUrl: './combos.component.html',
  styleUrls: ['./combos.component.scss'],
    providers: [PaqueteItemComponent],
})
export class CombosComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  clientOnlyRun: boolean = false;
  serverOnlyRun: boolean = false;

  loading: boolean = false;
  loginStatus: boolean = false;

  successMessage!: string;
  public _success = new Subject<string>();

  environment: any;
  combos: any[] = [];
  paquetes: any[] = [];

  constructor(
      public data: SharedService,
      private http: HttpClient,
      private auth: AutenticacionService,
      @Inject(PLATFORM_ID) private platformId: Object
    ) {
    this.environment = environment;

    if (isPlatformBrowser(this.platformId)) {
      this.clientOnlyRun = true;
    } else {
      this.serverOnlyRun = true;
    }
  }

  ngOnInit() {
    this._success.pipe(takeUntil(this.destroy$)).subscribe((message) => this.successMessage = message);
    this._success.pipe(takeUntil(this.destroy$), debounceTime(5000)).subscribe(() => this.successMessage = '');
    // subscribing to data on loginStatus
    this.data.currentLogin.pipe(takeUntil(this.destroy$)).subscribe(
      (status) => {
        this.loginStatus = status;
        // Client only code.
        if (this.clientOnlyRun) {
          const $public = this.loginStatus ? '' : 'public/';

          // Obtengo combos de la API
          this.auth.get($public + 'combos/listado')
          .then(($response)  => {
            console.log('aber combos', $response.response) // TODO: eliminar
            this.combos = $response.response;
          })
          .catch(($error) => {
            this.data.log('getcombos error combos:', $error);
          });

          // Obtengo Paquetes de la API
          this.auth.get($public + 'paquetes/listado')
          .then(($response)  => {
            console.log('aber paquetes', $response.response) // TODO: eliminar
            this.paquetes = $response.response;
          })
          .catch(($error) => {
            this.data.log('getpaquetes error combos:', $error);
          });
        }
      }
    )
  }

  ngAfterViewInit() {
    // Client only code.
    if (this.clientOnlyRun) {
      let combosIntervalAttemptsCount = 0;
      const combosIntervalId = setInterval(() => {
        if (combosIntervalAttemptsCount > 9) {
          clearInterval(combosIntervalId);
        }

        // Inicializo Swiper Combos
        try {
          // swiper element
          const swiperCombosEl: SwiperContainer | null = document.querySelector('#combos-swiper-productos-sina');

          if (swiperCombosEl) {
            // swiper parameters
            const swiperCombosParams = {
              init: true,
              navigation: true,
              pagination: false,
              scrollbar: false,
              loop: true,
              slidesPerView: 1,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 4,
                },
              },
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              // on: {
              //   init() {
              //     // ...
              //   },
              // },
              injectStyles: [`
                @media screen and (max-width: 768px){
                  .swiper-button-prev {
                    margin-left: -15px;
                  }
                  .swiper-button-next {
                    margin-right: -15px;
                  }
                  .swiper-button-next svg,
                  .swiper-button-prev svg {
                    width: 60%;
                    height: 60%;
                  }
                }
              `],
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperCombosEl, swiperCombosParams);

            // and now initialize it
            swiperCombosEl.initialize();
          } else {
            this.data.log('Combos Swiper not found');
          }
        } catch (error) {
          this.data.log('Combos Swiper initialization error:', error);
        }

        combosIntervalAttemptsCount++;
      }, 200);

      let paquetesIntervalAttemptsCount = 0;
      const paquetesIntervalId = setInterval(() => {
        if (paquetesIntervalAttemptsCount > 9) {
          clearInterval(paquetesIntervalId);
        }
        // Inicializo Swiper Paquetes
        try {
          // swiper element
          const swiperPaquetesEl: SwiperContainer | null = document.querySelector('#paquetes-swiper-productos-sina');

          if (swiperPaquetesEl) {
            // swiper parameters
            const swiperPaquetesParams = {
              init: true,
              navigation: true,
              pagination: false,
              scrollbar: false,
              loop: true,
              slidesPerView: 1,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 4,
                },
              },
              observeParents: true,
              observeSlideChildren: true,
              observer: true,
              // on: {
              //   init() {
              //     // ...
              //   },
              // },
              injectStyles: [`
                @media screen and (max-width: 768px){
                  .swiper-button-prev {
                    margin-left: -15px;
                  }
                  .swiper-button-next {
                    margin-right: -15px;
                  }
                  .swiper-button-next svg,
                  .swiper-button-prev svg {
                    width: 60%;
                    height: 60%;
                  }
                }
              `],
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperPaquetesEl, swiperPaquetesParams);

            // and now initialize it
            swiperPaquetesEl.initialize();
          } else {
            this.data.log('Paquetes Swiper not found');
          }
        } catch (error) {
          this.data.log('Paquetes Swiper initialization error:', error);
        }

        paquetesIntervalAttemptsCount++;
      }, 200)
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  alertClicked() {
    this.successMessage = '';
    this.data.toggleCarritoShow();
  }
}
